import { default as React } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import moment from "moment"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const BusinessOnlineBankingForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const today = moment().format("YYYY-MM-DD")

  const schema = Yup.object({
    company: Yup.string().required("Company Name is Required"),
    tax: Yup.string().required("Tax ID is Required"),
    address: Yup.string().required("Company Address is Required"),
    city: Yup.string().required("City is Required"),
    state: Yup.string().required("State is Required"),
    zip: Yup.number().required("Zip is Required"),
    name: Yup.string().required("Account Owner's Name is Required"),
    email: Yup.string().email().required("Email is Required"),
    phone: Yup.string().required('Phone Number is required'),
    // accounts: Yup.array().of(Yup.object({
    //   number: Yup.string().length(10),
    //   nickname: Yup.string()
    // })).required("Please fill at least one account number"),
    signature: Yup.string().required("Signature is Required"),
    title: Yup.string().required("Title is Required"),
    date: Yup.date().required('Date is requiered'),
  });

  const initialValues = {
    company: "",
    tax: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    name: "",
    email: "",
    phone: "",
    interests: [],
    accounts: [{
      number: '',
      nickname: ''
    }, {
      number: '',
      nickname: ''
    }, {
      number: '',
      nickname: ''
    }, {
      number: '',
      nickname: ''
    }],
    users: [{
      name: '',
      email: ''
    }, {
      name: '',
      email: ''
    }, {
      name: '',
      email: ''
    }, {
      name: '',
      email: ''
    }],
    third_party: {
      business: '',
      name: '',
      email: '',
      phone: ''
    },
    signature: "",
    title: "",
    date: today,
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isValid,
    isSubmitting,
    touched,
    errors,
  } = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, { resetForm }) => {
      const token = await executeRecaptcha("contact_form")
      try {
        const data = await fetch(`/api/recaptcha`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ token })
        }).then(response => {
          if (!response.ok) {
            throw new Error('Response from API call was not Ok')
          }
          return response.json()
        }).then(data => data)

        if (data.score > 0.5) {
          //safe to submit the form
          try {
            await fetch(`/api/mailer`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ id: 'onlineBusiness', ...values })
            }).then(response => {
              if (!response.ok) {
                throw new Error('Could not submit form')
              }
              return response.json()
            }).then(data => data)
            //set thank you message
            resetForm()
          } catch (e) {
            console.log(e)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
  })

  return (
    <div>
      <form
        action="/"
        className="grid grid-cols-1 gap-4"
        onSubmit={handleSubmit}>
        <div
          className="grid grid-cols-2 gap-4">
          <label
            htmlFor="company"
            className="block">
            <span className="text-primary font-heading text-xl">Company Name</span>
            <input
              value={values.company}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              id="company"
              name="company"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.company && touched.company ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Company Name" />
            {touched.company && errors.company ? (
              <div className="text-red-400 text-sm px-4 py-0.5">{errors.company}</div>
            ) : null}
          </label>
          <label
            htmlFor="tax"
            className="block">
            <span className="text-primary font-heading text-xl">Tax ID</span>
            <input
              value={values.tax}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              id="tax"
              name="tax"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.tax && touched.tax ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Tax ID" />
            {touched.tax && errors.tax ? (
              <div className="text-red-400 text-sm px-4 py-0.5">{errors.tax}</div>
            ) : null}
          </label>
        </div>
        <div
          className="grid grid-rows-2 gap-4">
          <label
            htmlFor="address"
            className="block">
            <span className="text-primary font-heading text-xl">Company Address</span>
            <input
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              id="address"
              name="address"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.address && touched.address ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Company Address" />
            {touched.address && errors.address ? (
              <div className="text-red-400 text-sm px-4 py-0.5">{errors.address}</div>
            ) : null}
          </label>
          <div
            className="grid grid-cols-3 gap-4">
            <label
              htmlFor="city"
              className="block">
              <span className="text-primary font-heading text-xl">City</span>
              <input
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                id="city"
                name="city"
                className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.city && touched.city ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                placeholder="City" />
              {touched.city && errors.city ? (
                <div className="text-red-400 text-sm px-4 py-0.5">{errors.city}</div>
              ) : null}
            </label>
            <label
              htmlFor="state"
              className="block">
              <span className="text-primary font-heading text-xl">State</span>
              <input
                value={values.state}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                id="state"
                name="state"
                className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.state && touched.state ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                placeholder="State" />
              {touched.state && errors.state ? (
                <div className="text-red-400 text-sm px-4 py-0.5">{errors.state}</div>
              ) : null}
            </label>
            <label
              htmlFor="zip"
              className="block">
              <span className="text-primary font-heading text-xl">Zip</span>
              <input
                value={values.zip}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                id="zip"
                name="zip"
                className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.zip && touched.zip ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                placeholder="Zip" />
              {touched.zip && errors.zip ? (
                <div className="text-red-400 text-sm px-4 py-0.5">{errors.zip}</div>
              ) : null}
            </label>
          </div>
        </div>
        <label
          htmlFor="name"
          className="block">
          <span className="text-primary font-heading text-xl">Main Contact</span>
          <input
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            id="name"
            name="name"
            className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.name && touched.name ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            placeholder="Main Contact" />
          {touched.name && errors.name ? (
            <div className="text-red-400 text-sm px-4 py-0.5">{errors.name}</div>
          ) : null}
        </label>
        <div
          className="grid grid-cols-2 gap-4">
          <label
            htmlFor="email"
            className="block">
            <span className="text-primary font-heading text-xl">Email Address</span>
            <input
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              id="email"
              name="email"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.email && touched.email ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Email" />
            {touched.email && errors.email ? (
              <div className="text-red-400 text-sm px-4 py-1">{errors.email}</div>
            ) : null}
          </label>
          <label
            htmlFor="phone"
            className="block">
            <span className="text-primary font-heading text-xl">Daytime Phone</span>
            <input
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              type="tel"
              id="phone"
              name="phone"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.phone && touched.phone ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Phone" />
            {touched.phone && errors.phone ? (
              <div className="text-red-400 text-sm px-4 py-1">{errors.phone}</div>
            ) : null}
          </label>
        </div>
        <div
          className="grid grid-rows-2 gap-4">
          <span className="text-primary font-heading text-xl">Interested In</span>
          <div
            className="grid grid-cols-6 gap-4">
            <label
              htmlFor="interests[0]"
              className="flex items-center">
              <span className="font-bold text-primary">Bill Pay</span>
              <input
                type="checkbox"
                value={`Bill Pay`}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mx-2"
                id="interests[0]"
                name="interests" />
            </label>
            <label
              htmlFor="interests[1]"
              className="flex items-center">
              <span className="font-bold text-primary">ACH</span>
              <input
                type="checkbox"
                value={`ACH`}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mx-2"
                id="interests[1]"
                name="interests" />
            </label>
            <label
              htmlFor="interests[2]"
              className="flex items-center">
              <span className="font-bold text-primary">Remote Deposit</span>
              <input
                type="checkbox"
                value={`Remote Deposit`}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mx-2"
                id="interests[2]"
                name="interests" />
            </label>
            <label
              htmlFor="interests[3]"
              className="flex items-center">
              <span className="font-bold text-primary">Business Mobile App</span>
              <input
                type="checkbox"
                value={`Business Mobile App`}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mx-2"
                id="interests[3]"
                name="interests" />
            </label>
            <label
              htmlFor="interests[4]"
              className="flex items-center">
              <span className="font-bold text-primary">Mobile Deposit</span>
              <input
                type="checkbox"
                value={`Mobile Deposit`}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mx-2"
                id="interests[4]"
                name="interests" />
            </label>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-heading text-primary">Accounts</h3>
          <div
            className="grid grid-cols-2 gap-4 mt-8">
            <label
              htmlFor="accounts[0]['number']"
              className="block">
              <span className="text-primary font-heading text-xl">Account Number One</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.accounts[0]['number']}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="accounts[0]['number']"
                    name="accounts[0]['number']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Account One" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[0]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.accounts[0]['nickname']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accounts[0]['nickname']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Nickname" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[0]}</div>
                  ) : null}
                </div>
              </div>
            </label>
            <label
              htmlFor="accounts[1]['number']"
              className="block">
              <span className="text-primary font-heading text-xl">Account Number Two</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.accounts[1]['number']}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accounts[1]['number']"
                    id="accounts[1]['number']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Account Two" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[1]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.accounts[1]['nickname']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accounts[1]['nickname']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Nickname" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[1]}</div>
                  ) : null}
                </div>
              </div>
            </label>
            <label
              htmlFor="accounts[2]['number']"
              className="block">
              <span className="text-primary font-heading text-xl">Account Number Three</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.accounts[2]['number']}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accounts[2]['number']"
                    id="accounts[2]['number']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Account Three" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[2]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.accounts[2]['nickname']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accounts[2]['nickname']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Nickname" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[2]}</div>
                  ) : null}
                </div>
              </div>
            </label>
            <label
              htmlFor="accounts[3]['number']"
              className="block">
              <span className="text-primary font-heading text-xl">Account Number Four</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.accounts[3]['number']}
                    type="number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accounts[3]['number']"
                    id="accounts[3]['number']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Account Four" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[3]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.accounts[3]['nickname']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="accounts[3]['nickname']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.accounts && touched.accounts ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Nickname" />
                  {touched.accounts && errors.accounts ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.accounts[3]}</div>
                  ) : null}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-heading text-primary">Authorized Users</h3>
          <div
            className="grid grid-cols-2 gap-4 my-8">
            <label
              htmlFor="users[0]['name']"
              className="block">
              <span className="text-primary font-heading text-xl">Authorized User One</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.users[0]['name']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[0]['name']"
                    id="users[0]['name']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Name" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[0]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.users[0]['email']}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[0]['email']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Email" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[0]}</div>
                  ) : null}
                </div>
              </div>
            </label>
            <label
              htmlFor="users[1]['name']"
              className="block">
              <span className="text-primary font-heading text-xl">Authorized User Two</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.users[1]['name']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[1]['name']"
                    id="users[1]['name']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Name" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[1]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.users[1]['email']}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[1]['email']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Email" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[1]}</div>
                  ) : null}
                </div>
              </div>
            </label>
            <label
              htmlFor="users[2]['name']"
              className="block">
              <span className="text-primary font-heading text-xl">Authorized User Three</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.users[2]['name']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[2]['name']"
                    id="users[2]['name']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Name" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[2]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.users[2]['email']}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[2]['email']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Email" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[2]}</div>
                  ) : null}
                </div>
              </div>
            </label>
            <label
              htmlFor="users[3]['name']"
              className="block">
              <span className="text-primary font-heading text-xl">Authorized User Four</span>
              <div
                className="grid grid-cols-2 gap-2">
                <div>
                  <input
                    value={values.users[3]['name']}
                    type="text"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[3]['name']"
                    id="users[3]['name']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Name" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[3]}</div>
                  ) : null}
                </div>
                <div>
                  <input
                    value={values.users[3]['email']}
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="users[3]['email']"
                    className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.users && touched.users ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                    placeholder="Email" />
                  {touched.users && errors.users ? (
                    <div className="text-red-400 text-sm px-4 py-1">{errors.users[3]}</div>
                  ) : null}
                </div>
              </div>
            </label>
          </div>
        </div>
        <div className="space-y-2">
          <h3 className="text-2xl font-heading text-primary">Third Party Users</h3>
          <div
            className="grid grid-cols-6 md:grid-cols-12 gap-4 my-8">

            <div
              className="col-span-3 gap-2">
              <div>
                <label
                  htmlFor="third_party['name']"
                  className="block">
                  <span className="text-primary font-heading text-xl">Name</span>
                </label>
                <input
                  value={values.third_party['name']}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="third_party['name']"
                  id="third_party['name']"
                  className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.third_party && touched.third_party ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                  placeholder="Name" />
                {touched.third_party && errors.third_party ? (
                  <div className="text-red-400 text-sm px-4 py-1">{errors.third_party}</div>
                ) : null}
              </div>
            </div>

            <div
              className="col-span-3 gap-2">
              <div>
                <label
                  htmlFor="third_party['email']"
                  className="block">
                  <span className="text-primary font-heading text-xl">Email</span>
                </label>
                <input
                  value={values.third_party['email']}
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="third_party['email']"
                  id="third_party['email']"
                  className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.third_party && touched.third_party ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                  placeholder="Email" />
                {touched.third_party && errors.third_party ? (
                  <div className="text-red-400 text-sm px-4 py-1">{errors.third_party}</div>
                ) : null}
              </div>
            </div>

            <div
              className="col-span-3 gap-2">
              <div>
                <label
                  htmlFor="third_party['business']"
                  className="block">
                  <span className="text-primary font-heading text-xl">Business</span>
                </label>
                <input
                  value={values.third_party['business']}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="third_party['business']"
                  id="third_party['business']"
                  className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.third_party && touched.third_party ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                  placeholder="business" />
                {touched.third_party && errors.third_party ? (
                  <div className="text-red-400 text-sm px-4 py-1">{errors.third_party}</div>
                ) : null}
              </div>
            </div>

            <div
              className="col-span-3 gap-2">
              <div>
                <label
                  htmlFor="third_party['phone']"
                  className="block">
                  <span className="text-primary font-heading text-xl">Phone</span>
                </label>
                <input
                  value={values.third_party['phone']}
                  type="tel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="third_party['phone']"
                  id="third_party['phone']"
                  className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.third_party && touched.third_party ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
                  placeholder="phone" />
                {touched.third_party && errors.third_party ? (
                  <div className="text-red-400 text-sm px-4 py-1">{errors.third_party}</div>
                ) : null}
              </div>
            </div>

          </div>
        </div>
        <div
          className="grid grid-cols-3 gap-4">
          <label
            htmlFor="signature"
            className="block">
            <span className="text-primary font-heading text-xl">Signature</span>
            <input
              value={values.signature}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              id="signature"
              name="signature"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.signature && touched.signature ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Signature" />
            {touched.signature && errors.signature ? (
              <div className="text-red-400 text-sm px-4 py-0.5">{errors.signature}</div>
            ) : null}
          </label>
          <label
            htmlFor="title"
            className="block">
            <span className="text-primary font-heading text-xl">Title</span>
            <input
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              id="title"
              name="title"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.title && touched.title ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
              placeholder="Title" />
            {touched.title && errors.title ? (
              <div className="text-red-400 text-sm px-4 py-0.5">{errors.title}</div>
            ) : null}
          </label>
          <label
            htmlFor="date"
            className="block">
            <span className="text-primary font-heading text-xl">Date</span>
            <input
              value={values.date}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
              id="date"
              name="date"
              className={`mt-1 block w-full rounded-md py-3 px-4 ${errors.date && touched.date ? 'border-red-300' : 'border-gray-200'} placeholder-gray-300`}
            />
            {touched.date && errors.date ? (
              <div className="text-red-400 text-sm px-4 py-0.5">{errors.date}</div>
            ) : null}
          </label>
        </div>
        <div
          className="mt-4">
          <button
            disabled={!isValid || isSubmitting}
            className="btn btn-secondary"
            type="submit">Send Message</button>
        </div>
      </form>
    </div>
  )
}

export default BusinessOnlineBankingForm